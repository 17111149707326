/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ZonedDateTime } from "@js-joda/core";
import { Box, Pagination, PaginationItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Buffer } from "buffer";
import React, { useEffect, useState } from "react";

import { SettingsMenu } from "../components/SettingsMenu";
import { useAppSelector } from "../store/hooks";

const container = css`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 1280px) {
    font-size: 13px;
  }
`;

const buttonContainer = css`
  display: flex;
  justify-content: center;
`;

const dayContainer = css`
  margin-bottom: 40px;
`;

const gameContainer = css`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const teamContainer = css`
  flex: 1;
`;

const homeTeamContainer = css`
  text-align: right;
`;

const awayTeamContainer = css`
  text-align: left;
`;

const timeContainer = css`
  width: 80px;

  @media (min-width: 1280px) {
    width: 100px;
  }
`;

const paginationContainer = css`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

type Game = {
  id: string;
  homeTeam: {
    id: string;
    city: string;
    name: string;
    abbreviation: string | null;
  };
  awayTeam: {
    id: string;
    city: string;
    name: string;
    abbreviation: string | null;
  };
  startTime: string;
};

type Games = {
  [key: string]: Game[];
};

type PaginationDetails = {
  totalItems: number;
  totalPages: number;
};

type Response = {
  results: Games;
  pagination: PaginationDetails;
};

const getGameListResponse = async (
  seasonId: string,
  teamId?: string,
  timeZone?: string,
  pastGames?: boolean,
  primeTime?: boolean,
  page?: number
): Promise<Response> => {
  const username = "user";
  const password = "qNIWrpU2nKyoUTEkIVP4";

  try {
    const response = await fetch("/api/v1/game/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${Buffer.from(`${username}:${password}`).toString(
          "base64"
        )}`,
      },
      body: JSON.stringify({
        seasonId,
        teamId,
        timeZone,
        pastGames,
        primeTime,
        page,
      }),
    });

    if (!response.ok) {
      throw new Error("Request failed");
    }

    const data: Response = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

const getDayString = (dateString: string) => {
  // Input is in format 2023-10-04
  // Output should be in format Wednesday 4.10.2023
  const date = new Date(dateString);
  const day = date.getDay();
  const dayString = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return `${days[day]} ${dayString}.${month + 1}.${year}`;
};

const getMonthString = (dateString: string) => {
  const date = new Date(dateString);
  const month = date.getMonth();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "Juli",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return `${months[month]}`;
};

const getTime = (isoString: string) => {
  const zonedDateTime = ZonedDateTime.parse(isoString);
  const hours = zonedDateTime.hour();
  const minutes = zonedDateTime.minute();

  return `${hours.toString().padStart(2, "0")}.${minutes
    .toString()
    .padStart(2, "0")}`;
};

type RenderedGames = JSX.Element[];

const renderGames = (games: Games) => {
  let currentMonth = "";
  const renderedGames: RenderedGames = [];

  Object.keys(games).forEach((key) => {
    const month = getMonthString(key);

    // Check if the month has changed
    if (month !== currentMonth) {
      // If it has changed, create a new monthContainer
      currentMonth = month;
      renderedGames.push(
        <Box key={`month-${month}`}>
          <h2>{month}</h2>
        </Box>
      );
    }

    renderedGames.push(
      <Box key={`day-${key}`} css={dayContainer}>
        <h3>{getDayString(key)}</h3>
        {games[key].map((game) => (
          <Box key={game.id} css={gameContainer}>
            <Box css={[teamContainer, homeTeamContainer]}>
              {game.homeTeam.city} {game.homeTeam.name}
            </Box>
            <Box css={timeContainer}>{getTime(game.startTime)}</Box>
            <Box css={[teamContainer, awayTeamContainer]}>
              {game.awayTeam.city} {game.awayTeam.name}
            </Box>
          </Box>
        ))}
      </Box>
    );
  });

  return renderedGames;
};

export const Main: React.FC = () => {
  const seasonId = useAppSelector((state) => state.settings.selectedSeason);
  const teamId = useAppSelector((state) => state.settings.selectedTeam);
  const pastGames = useAppSelector((state) => state.settings.pastGames);
  const primeTime = useAppSelector((state) => state.settings.primeTime);
  const timeZone = useAppSelector((state) => state.settings.selectedTimeZone);

  const [games, setGames] = useState<Games | undefined>(undefined);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const fetchGames = async () => {
      const { results: gamesResponse, pagination } = await getGameListResponse(
        seasonId,
        teamId,
        timeZone,
        pastGames,
        primeTime,
        page
      );

      setGames(gamesResponse);
      setTotalPages(pagination.totalPages);
    };

    fetchGames();
  }, [seasonId, teamId, timeZone, pastGames, primeTime, page]);

  useEffect(() => {
    setPage(1);
  }, [teamId, timeZone, pastGames, primeTime]);

  return (
    <Box css={container}>
      <Box css={buttonContainer}>
        <SettingsMenu />
      </Box>
      <h1>NHL season 2024 - 2025</h1>
      {games && renderGames(games)}

      <Box css={paginationContainer}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChange}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
            />
          )}
        />
      </Box>
    </Box>
  );
};
