/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import { Route, Routes } from "react-router-dom";

import { Main } from "./routes/Main";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import {
  setSelectedSeason,
  setSelectedTimeZone,
  setSettingsVersion,
  setTeams,
  setTimeZones,
} from "./store/settingsSlice";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "#606060",
          "&.Mui-checked": {
            color: "#FFFFFF",
          },
        },
        track: {
          opacity: 0.2,
          backgroundColor: "#fff",
          "$checked$checked + &": {
            opacity: 0.7,
            backgroundColor: "#fff",
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          "& .MuiPaginationItem-ellipsis": {
            color: "white",
          },
          "& .Mui-selected": {
            backgroundColor: "#E0E0E0!important",
            color: "#000",
            "&:hover": {
              backgroundColor: "#E0E0E0",
            },
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: "white",
          "& .Mui-selected": {
            backgroundColor: "#E0E0E0",
            color: "#000",
            "&:hover": {
              backgroundColor: "#E0E0E0",
            },
          },
        },
      },
    },
  },
});

const container = css`
  width: 100%;
  position: relative;
  min-height: 100vh;

  @media (min-width: 1280px) {
    width: 1280px;
    margin: 0 auto;
  }
`;

const topBar = css`
  display: flex;
  margin-top: 20px;
`;

const sideContainer = css`
  flex: 1;
  color: white;
`;

const leftSideContainer = css`
  text-align: right;

  & img {
    display: none;
  }

  @media (min-width: 1280px) {
    & img {
      display: inline;
      margin-right: 20px;
    }
  }
`;

const logoContainer = css`
  text-align: center;
  width: 90%;

  @media (min-width: 1280px) {
    width: 630px;

    & img {
      margin-top: 20px;
    }
  }
`;

const content = css`
  margin: 0 10px;
  padding-top: 30px;
`;

const useSettings = () => {
  // TODO: Change settings to be fetched from the server
  const dispatch = useAppDispatch();

  const settingsVersion = useAppSelector(
    (state) => state.settings.settingsVersion
  );

  // UUIDs are from production data
  //const selectedSeason = "ec8e9234-8a30-4e64-9d79-e2f24b9eb1a6";
  const selectedSeason = "40854b15-ffa6-4612-b15b-1c1220a5055c";

  const teams = [
    {
      id: "c64f0ecf-495a-4164-8965-6bb1c803a751",
      city: "Anaheim",
      name: "Ducks",
    },
    {
      id: "17701421-d71f-4c40-adf1-ef1095c9c219",
      city: "Boston",
      name: "Bruins",
    },
    {
      id: "711a24e8-1442-4503-960a-2151182438f4",
      city: "Buffalo",
      name: "Sabres",
    },
    {
      id: "7e921cfd-9831-43f3-8f60-3bd1db40a0ac",
      city: "Calgary",
      name: "Flames",
    },
    {
      id: "56d6043f-a3a2-4d70-8bc9-be922404940d",
      city: "Carolina",
      name: "Hurricanes",
    },
    {
      id: "50c5c539-b0ab-4fdc-8b1e-5ba4c6674a1e",
      city: "Chicago",
      name: "Blackhawks",
    },
    {
      id: "1299c653-4a63-4c8f-8794-2de22cc30d43",
      city: "Colorado",
      name: "Avalanche",
    },
    {
      id: "5f2001d8-5946-4487-86c4-b587b9023599",
      city: "Columbus",
      name: "Blue Jackets",
    },
    {
      id: "a2a048f4-01e0-42c7-9973-8ac100cb0c67",
      city: "Dallas",
      name: "Stars",
    },
    {
      id: "e724bb63-8e40-484c-ab0b-678faddccab1",
      city: "Detroit",
      name: "Red Wings",
    },
    {
      id: "573d68f4-68e3-4c73-be29-ab101855f99f",
      city: "Edmonton",
      name: "Oilers",
    },
    {
      id: "6bb3b984-3ea1-4f2d-94c2-6a10e0577cc5",
      city: "Florida",
      name: "Panthers",
    },
    {
      id: "c7a5bea7-7341-4e36-9869-c986986a0090",
      city: "Los Angeles",
      name: "Kings",
    },
    {
      id: "c984d887-ff66-44ef-8091-50d87c6b951f",
      city: "Minnesota",
      name: "Wild",
    },
    {
      id: "79302d02-b585-42a7-bd42-e7c075189cfb",
      city: "Montréal",
      name: "Canadiens",
    },
    {
      id: "a88ef233-93e4-4e3a-9869-e875cf51e6d0",
      city: "New York",
      name: "Islanders",
    },
    {
      id: "868994b6-41cd-44ad-bc64-831a24726754",
      city: "New York",
      name: "Rangers",
    },
    {
      id: "f7d11a8e-2189-434c-9a01-352a1a630af8",
      city: "Nashville",
      name: "Predators",
    },
    {
      id: "130476f2-d272-4e37-a0c2-910a3b5d5d33",
      city: "New Jersey",
      name: "Devils",
    },
    {
      id: "729116c1-ded0-419e-8e94-a3015613b59f",
      city: "Ottawa",
      name: "Senators",
    },
    {
      id: "e79f97d9-1de8-45f7-81a7-e442e2720dd1",
      city: "Philadelphia",
      name: "Flyers",
    },
    {
      id: "3281fa41-f23f-407f-aa0d-3cccfec9d66d",
      city: "Pittsburgh",
      name: "Penguins",
    },
    {
      id: "405c2971-4f8d-4969-8595-cda188c72e9f",
      city: "San Jose",
      name: "Sharks",
    },
    {
      id: "b400decc-d041-4b3e-a230-e39f591b683c",
      city: "Seattle",
      name: "Kraken",
    },
    {
      id: "49ba3899-c654-4dc7-94b7-7365780b33bb",
      city: "St. Louis",
      name: "Blues",
    },
    {
      id: "79a41178-c3e6-4567-8ad2-10dfa038dcb6",
      city: "Tampa Bay",
      name: "Lightning",
    },
    {
      id: "4d4ce7fc-b104-4685-8b9e-519e958a30fe",
      city: "Toronto",
      name: "Maple Leafs",
    },
    {
      id: "ef7fd0bd-54ff-45a3-8f72-d53c9d6eae74",
      city: "Utah",
      name: "Hockey Club",
    },
    {
      id: "69a3b1b2-331e-400d-9979-b7eb5670d0b1",
      city: "Vancouver",
      name: "Canucks",
    },
    {
      id: "304768f2-d11a-4e49-bad9-6a1c034a2f53",
      city: "Vegas",
      name: "Golden Knights",
    },
    {
      id: "2c523021-7aef-4e21-a15b-8b61328b2377",
      city: "Washington",
      name: "Capitals",
    },
    {
      id: "31ea2178-0ed0-4d8e-a775-28518d21b1f5",
      city: "Winnipeg",
      name: "Jets",
    },
  ];

  const timeZones = ["UTC", "America/New_York", "Europe/Helsinki"];
  const CURRENT_SETTINGS_VERSION = 3;

  if (!settingsVersion || settingsVersion < CURRENT_SETTINGS_VERSION) {
    console.log("Setting teams");
    dispatch(setSettingsVersion(CURRENT_SETTINGS_VERSION));
    dispatch(setSelectedSeason(selectedSeason));
    dispatch(setTeams(teams));
    dispatch(setTimeZones(timeZones));
    dispatch(setSelectedTimeZone("Europe/Helsinki"));
  }
};

const App: React.FC = () => {
  useSettings();

  return (
    <ThemeProvider theme={theme}>
      <Box css={container}>
        <Box css={topBar}>
          <Box css={[sideContainer, leftSideContainer]}>
            <img src="images/icon.svg" alt="Calendar icon" />
          </Box>
          <Box css={logoContainer}>
            <img src="images/logo.svg" alt="Main logo" />
          </Box>
          <Box css={sideContainer}></Box>
        </Box>
        <Box css={content}>
          <Routes>
            <Route path="/" element={<Main />} />
          </Routes>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default App;
