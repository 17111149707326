/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  setPastGames,
  setPrimeTime,
  setSelectedTeam,
  setSelectedTimeZone,
} from "../store/settingsSlice";

const buttonText = css`
  margin-top: 4px;
`;

const getMenu = (isOpen: boolean) => css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 250px;
  background-color: #202020;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  z-index: 10;

  ${isOpen ? `transform: translateX(0);` : `transform: translateX(100%);`}
`;

const closeIconContainer = css`
  display: flex;
  justify-content: space-between;
  color: white;
  text-align: right;
  font-size: 16px;
  margin-bottom: 40px;
`;

const closeIcon = css`
  cursor: pointer;
`;

const formElementContainer = css`
  margin-bottom: 40px;
`;

const inputLabel = css`
  color: white;
`;

const linkButton = css`
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 10px 0;
  text-transform: none;
`;

export const SettingsMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const teams = useAppSelector((state) => state.settings.teams);
  const timeZones = useAppSelector((state) => state.settings.timeZones);

  const selectedTeam = useAppSelector((state) => state.settings.selectedTeam);
  const selectedTimeZone = useAppSelector(
    (state) => state.settings.selectedTimeZone
  );
  const pastGames = useAppSelector((state) => state.settings.pastGames);
  const primeTime = useAppSelector((state) => state.settings.primeTime);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // The selected team is also controlled with the useState hook, so that the
  // cleared value is an empty string. Otherwise, the value would be undefined
  // and that is not a valid value for the Select component. That is still the
  // value to send to the store and also to the backend.
  const [team, setTeam] = useState(selectedTeam ?? "");
  const [timeZone, setTimeZone] = useState(selectedTimeZone);

  const dispatch = useAppDispatch();

  const handleChangeTeam = (event: SelectChangeEvent) => {
    const teamId = event.target.value;
    setTeam(teamId);
    dispatch(setSelectedTeam(teamId));
  };

  const handleChangeTimeZone = (event: SelectChangeEvent) => {
    const timeZone = event.target.value;
    setTimeZone(timeZone);
    dispatch(setSelectedTimeZone(timeZone));
  };

  const clearTeamSelection = () => {
    setTeam("");
    dispatch(setSelectedTeam(undefined));
  };

  const handleChangePastGames = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setPastGames(event.target.checked));
  };

  const handleChangePrimeTime = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setPrimeTime(event.target.checked));
  };

  const formatTimeZoneName = (timeZone: string) => {
    const parts = timeZone.split("/");

    if (parts.length === 1) {
      return parts[0];
    }

    return `${parts[0]}: ${parts[1].replace(/_/g, " ")}`;
  };

  return (
    <Box>
      <Button
        variant="contained"
        startIcon={<SettingsIcon />}
        onClick={toggleMenu}
        size="small"
      >
        <span css={buttonText}>{isOpen ? "Close" : "Open"} settings</span>
      </Button>
      <Box css={getMenu(isOpen)}>
        <Box css={closeIconContainer}>
          Settings <CloseIcon css={closeIcon} onClick={toggleMenu} />
        </Box>

        <Box css={formElementContainer}>
          <FormControl component="fieldset">
            <FormControlLabel
              value="pastGames"
              control={
                <Switch onChange={handleChangePastGames} checked={pastGames} />
              }
              label="Show past games"
              labelPlacement="end"
            />
            <FormControlLabel
              value="primeTime"
              control={
                <Switch onChange={handleChangePrimeTime} checked={primeTime} />
              }
              label="Only prime time games"
              labelPlacement="end"
            />
          </FormControl>
        </Box>

        <Box css={formElementContainer}>
          <FormControl fullWidth size="small">
            <InputLabel id="select-team-label" css={inputLabel}>
              Filter by team
            </InputLabel>
            <Select
              labelId="select-team-label"
              id="select-team"
              value={team}
              label="Filter by team"
              onChange={handleChangeTeam}
            >
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  {team.city} {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {team && (
            <Button css={linkButton} onClick={clearTeamSelection}>
              Clear team selection
            </Button>
          )}
        </Box>

        <Box css={formElementContainer}>
          <FormControl fullWidth size="small">
            <InputLabel id="select-time-zone-label" css={inputLabel}>
              Select time zone
            </InputLabel>
            <Select
              labelId="select-time-zone-label"
              id="select-time-zone"
              value={timeZone}
              label="Select time zone"
              onChange={handleChangeTimeZone}
            >
              {timeZones.map((timeZone) => (
                <MenuItem key={timeZone} value={timeZone}>
                  {formatTimeZoneName(timeZone)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};
