import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface Team {
  id: string;
  city: string;
  name: string;
}

interface SettingsState {
  selectedSeason: string;
  teams: Team[];
  selectedTeam?: string;
  timeZones: string[];
  selectedTimeZone?: string;
  pastGames: boolean;
  primeTime: boolean;
  settingsVersion?: number;
}

export const initialState: SettingsState = {
  selectedSeason: "",
  teams: [],
  selectedTeam: undefined,
  timeZones: [],
  selectedTimeZone: undefined,
  pastGames: false,
  primeTime: false,
  settingsVersion: undefined,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSelectedSeason: (state, action: PayloadAction<string>) => {
      state.selectedSeason = action.payload;
    },
    setTeams: (state, action: PayloadAction<Team[]>) => {
      state.teams = action.payload;
    },
    setSelectedTeam: (state, action: PayloadAction<string | undefined>) => {
      state.selectedTeam = action.payload;
    },
    setTimeZones: (state, action: PayloadAction<string[]>) => {
      state.timeZones = action.payload;
    },
    setSelectedTimeZone: (state, action: PayloadAction<string | undefined>) => {
      state.selectedTimeZone = action.payload;
    },
    setPastGames: (state, action: PayloadAction<boolean>) => {
      state.pastGames = action.payload;
    },
    setPrimeTime: (state, action: PayloadAction<boolean>) => {
      state.primeTime = action.payload;
    },
    setSettingsVersion: (state, action: PayloadAction<number | undefined>) => {
      state.settingsVersion = action.payload;
    },
    setTeamsAndTimeZones: (
      state,
      action: PayloadAction<{ teams: Team[]; timeZones: string[] }>
    ) => {
      return {
        ...state,
        teams: action.payload.teams,
        timeZones: action.payload.timeZones,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedSeason,
  setTeams,
  setSelectedTeam,
  setTimeZones,
  setSelectedTimeZone,
  setPastGames,
  setPrimeTime,
  setSettingsVersion,
  setTeamsAndTimeZones,
} = settingsSlice.actions;

export default settingsSlice.reducer;
